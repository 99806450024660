import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import myImg from '../../Assets/avatar.png';
import Tilt from 'react-parallax-tilt';
import { AiFillGithub } from 'react-icons/ai';
import { FaLinkedinIn } from 'react-icons/fa';
import { AiFillMail } from 'react-icons/ai';
import { useTranslation } from '../../LanguageContext';

function Home2() {
  const { t } = useTranslation();

  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: '2.6em' }}>
              {t('home2Introduce')}{' '}
              <span className="purple"> {t('home2Myself')} </span>{' '}
            </h1>
            <p>
              {t('home2Hello')} <b className="purple"> JAVA</b>{' '}
              {t('home2Journey')} <b className="purple">CyberSec</b>,{' '}
              {t('home2Passion')}
            </p>
            <p></p>
            <p>
              <b className="purple">{t('home2Join')}</b> {t('home2Adventure')}
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>{t('home2FindMe')}</h1>
            <p>{t('home2Connect')}</p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/HuBiT55"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/hubert-przypis-a62b2b214/"
                  target="_blank"
                  rel="LinkedIn noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="mailto:hubert.przypis.it@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillMail />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
