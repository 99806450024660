import React, { createContext, useContext, useState, useEffect } from 'react';

const translations = {
  en: {
    home: 'Home',
    about: 'About',
    resume: 'Resume',
    projects: 'Projects and Realizations',
    contact: 'Contact',
    projectsTitle: 'Realizations and my own projects',
    OnlineProjectsTitle: 'Glad you want to test my stuff :)',
    findMoreText: 'Find more on my GitHub.',
    githubButton: 'GitHub',
    visitButton: 'Visit',
    closeButton: 'Close',
    aboutMeTitle: 'About me',
    aboutMeText: `I am an experienced IT support specialist with almost 5 years of experience in practical
        setting up, repairing and maintaining computer systems. I am dedicated to continuous improvement,
        therefore, I have completed the CISCO CCNA course and actively develop my skills by taking part in training courses or conferences`,
    passionsTitle: 'Passions',
    passionsText: `Beyond my academic pursuits, I am deeply passionate about emerging
      technologies such as Machine Learning, Artificial Intelligence, and
      Cybersecurity. These areas not only captivate my interest but also
      drive my ongoing exploration and learning journey.`,
    aboutTitle: 'Hi Everyone, My name is Hubert',
    aboutSubtitle: "I'm first-year",
    aboutDegree: 'computer science',
    aboutDegreeInfo: 'student.',
    skillsetTitle: 'Skillset',
    homeGreeting: 'Hi There!',
    homeMyNameIs: 'My name is',
    flag: '/flags/usa.png',
    home2Introduce: 'LET ME',
    home2Myself: 'INTRODUCE MYSELF',
    home2Hello: "Hello! My name is Hubert and I'm on a",
    home2Journey: 'journey, passionately exploring the IT world.',
    home2Passion:
      'Aspiring to become a skilled Java developer and CyberSecurity specialist. I am dedicated to continuous learning to get better every day. Besides my main interest, I am interested in cars and sports.',
    home2Aspiring: 'Aspiring to become a skilled Java',
    home2Developer: 'developer and CyberSecurity',
    home2Specialist:
      'specialist, I am dedicated to continuous learning to get better every day. Besides my main interest, I am interested in cars and sports.',
    home2Dedicated:
      'dedicated to continuous learning to get better every day. Besides my main interest, I am interested in cars and sports.',
    home2Learning:
      'to get better every day. Besides my main interest, I am interested in cars and sports.',
    home2Join: 'Join me',
    home2Adventure: 'on this exciting adventure!',
    home2FindMe: 'FIND ME ON',
    home2Connect: 'Feel free to connect with me',
    softwareDevelopment: 'Software development',
    cyberSecurity: 'CyberSecurity',
    webDevelopment: 'Web development',
    itSpecialist: 'IT specialist',
    seo: 'SEO',
    marketingAds: 'Marketing/Ads',
    contactTitle: 'Contact me',
    contactDescription:
      'If you have questions or want to get in touch, click the icon below',
    contactInfoTitle: 'Contact information (click to copy)',
    contactEmailMain: 'main E-mail',
    contactEmail2: 'E-mail 2',
    contactSecureEmail: 'secure E-mail',
    contactPhoneNumber: 'Personal number',
    downloadCV: 'Download CV',
    ebmDescription: 'Website made for dental clinic',
    pimpDescription: 'CMS store with modeling parts',
    hubertDescription: 'The best website I have ever seen',
    OnlineDescription1: 'Simple password generator',
    OnlineDescription2: 'Check the speed and accuracy of your writing',
    OnlineDescription3: ' Fighting for Life and Death. Will you accept the challenge?',
    BackButton: 'Back to projects',
    ProjectInButton:'Online programs',
    browserPlay: 'open in a new tab',
    },
  pl: {
    downloadCV: 'Pobierz CV',
    home: 'Strona główna',
    about: 'O mnie',
    resume: 'CV',
    projects: 'Projekty i realizacje',
    contact: 'Kontakt',
    projectsTitle: 'Realizacje i moje projekty',
    OnlineProjectsTitle: 'Miło mi że chcesz przetestować moje rzeczy :)',
    findMoreText: 'Znajdź więcej na moim GitHubie.',
    githubButton: 'GitHub',
    visitButton: 'Odwiedź',
    closeButton: 'Zamknij',
    flag: '/flags/poland.png',
    aboutMeTitle: 'O mnie',
    aboutMeText: `Jestem doświadczonym specjalistą wsparcia IT z prawie 5-letnim doświadczeniem w zakresie praktycznego montażu, naprawy oraz utrzymania systemów komputerowych. Stawiam na ciągły rozwój, dlatego ukończyłem kurs CISCO CCNA i aktywnie poszerzam swoje kompetencje, uczestnicząc w szkoleniach oraz konferencjach.`,
    passionsTitle: 'Pasje',
    passionsText: `Poza moimi zainteresowaniami akademickimi, pasjonuję się nowymi
        technologiami takimi jak Machine Learning, Artificial Intelligence i
        Cybersecurity. Te obszary nie tylko mnie fascynują, ale także napędzają
        moją ciągłą eksplorację i naukę.`,
    aboutTitle: 'Cześć Wszystkim, Mam na imię Hubert',
    aboutSubtitle: 'Jestem pierwszorocznym',
    aboutDegree: 'studentem informatyki',
    aboutDegreeInfo: '.',
    skillsetTitle: 'Umiejętności',
    homeGreeting: 'Cześć!',
    homeMyNameIs: 'Nazywam się',
    home2Introduce: 'POZWÓL, ŻE SIĘ',
    home2Myself: 'PRZEDSTAWIĘ',
    home2Hello: 'Cześć! Mam na imię Hubert i jestem zainteresowany językiem',
    home2Journey: ' i z pasją odkrywam świat IT oraz',
    home2Passion:
      'Cel to stać się wykwalifikowanym programistą Java i specjalistą CyberSecurity. Poświęcam dużo czasu na ciągłym uczeniu się, aby każdego dnia stawać się lepszym. Oprócz mojego głównego zainteresowania, interesuję się również samochodami i sportem.',
    home2Aspiring: 'Cel to stać się yberSecurity',
    home2Dedicated:
      'poświęcam dużo czasu na ciągłym uczeniu się, aby każdego dnia stawać się lepszym. Oprócz mojego głównego zainteresowania, interesuję się również samochodami i sportem.',
    home2Specialist:
      'poświęcam dużo czasu na ciągłym uczeniu się, aby każdego dnia stawać się lepszym. Oprócz mojego głównego zainteresowania, interesuję się również samochodami i sportem.',
    home2Learning:
      'aby każdego dnia stawać się lepszym. Oprócz mojego głównego zainteresowania, interesuję się również samochodami i sportem.',
    home2Join: 'Dołącz do mnie',
    home2Adventure: 'w tej ekscytującej przygodzie!',
    home2FindMe: 'ZNAJDŹ MNIE NA',
    home2Connect: 'Zapraszam do połączenia się ze mną',
    softwareDevelopment: 'Rozwój oprogramowania',
    cyberSecurity: 'CyberBezpieczeństwo',
    webDevelopment: 'Budowanie stron internetowych',
    itSpecialist: 'Specjalista IT',
    seo: 'SEO',
    marketingAds: 'Marketing/Reklamy',
    contactTitle: 'Skontaktuj się ze mną',
    contactDescription:
      'Jeśli masz pytania lub chcesz się skontaktować, kliknij poniższą ikonę',
    contactInfoTitle: 'Informacje kontaktowe',
    contactEmailMain: 'Główny E-mail',
    contactEmail2: 'E-mail slużbowy',
    contactSecureEmail: 'Szyfrowany bezpieczny E-mail',
    contactPhoneNumber: 'Numer telefonu',
    ebmDescription: 'Strona stworzona dla kliniki stomatologicznej',
    pimpDescription: 'Sklep CMS z częściami do modelowania',
    hubertDescription: 'Najlepsza strona jaką kiedykolwiek widziałem, serio.',
    OnlineDescription1: 'Prosty generator haseł',
    OnlineDescription2: 'Sprawdź prędkość i dokładność Twojego pisania',
    OnlineDescription3: 'WALKA NA ŚMIERC I ŻYCIE. Podejmiesz wyzwanie?',
    BackButton: 'Powrót do projektów',
    ProjectInButton:'Programy online',
    browserPlay: 'Otwórz w nowej karcie',
  },
};

const LanguageContext = createContext();

export const useTranslation = () => {
  const { language, setLanguage, currentFlag } = useContext(LanguageContext);

  const t = (key) => translations[language][key] || key;

  return { t, setLanguage, currentFlag };
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en'); // Ustawienie domyślnego języka
  const [currentFlag, setCurrentFlag] = useState(translations['en'].flag);

  useEffect(() => {
    setCurrentFlag(translations[language].flag);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage, currentFlag }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;